import {
    CheckOutlined,
    DownloadOutlined,
    DownOutlined,
    SearchOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import {
    Button,
    Descriptions,
    Dropdown,
    Flex,
    Input,
    Select,
    Table,
    Tooltip,
} from "antd";
import defaultLocale from "antd/locale/en_US";
import ipaddr from "ipaddr.js";
import { useCallback, useMemo, useState } from "react";

import IpLink from "components/link/Ip";
import { Portal } from "components/Portal";
import CountrySelector from "components/selector/CountrySelect";

import { columns, loadTarpitLog } from "api/tarpitLog";
import useDualState from "hooks/useDualState";
import useQuery from "hooks/useQuery";
import useQueryOptions from "hooks/useQueryOptions";
import useSwallowEventCallback from "hooks/useSwallowEventCallback";
import useUpdateEffect from "hooks/useUpdateEffect";
import async from "lib/async";
import { downloadCsv } from "lib/csv";
import domainNameRegex from "lib/domainNameRegex";
import supabasePayloads from "lib/supabase/payloads";

const defaultQueryOptions = [
    { value: "ip", label: "Source IP" },
    { value: "forwardedFor", label: "Forwarded for (IP)" },
    { value: "payload", label: "Payload" },
    { value: "headers", label: "Headers" },
    { value: "threat", label: "Threat" },
    { value: "domain", label: "Domain" },
    { value: "companyName", label: "Company name" },
    { value: "companyDomain", label: "Company domain" },
    { value: "cidr", label: "CIDR" },
];

const params = [
    {
        name: "country",
        default: null,
    },
    {
        name: "query",
        default: "",
        serialize: (v) => (v ? v.trim() : undefined),
    },
    {
        name: "queryBy",
        default: null,
    },
    {
        name: "payload",
        default: null,
        autoApply: true,
    },
    {
        name: "companyId",
        default: null,
    },
    {
        name: "domainId",
        default: null,
    },
    {
        name: "blacklisted",
        default: null,
        autoApply: true,
        serialize: (v) =>
            v !== null && v !== undefined && v !== ""
                ? v.toString()
                : undefined,
        unserialize: (v) => v === "true" ? true : v === "false" ? false : null,
    },
];

const locale = {
    ...defaultLocale.Table,
    emptyText: "No data in the last two days",
};

function downloadFile(data, filename = "data.txt", mimeType) {
    // 'text/csv;charset=utf-8;'
    const blob = new Blob([ data ], { type: mimeType });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    }
    else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

function Content({ id, content }) {
    const [ decoded, setDecoded ] = useState("");

    const onTryDecoding = useCallback(() => {
        try {
            const decoded = atob(content);
            setDecoded(decoded);
        }
        catch (err) {
            setDecoded("Error: " + err.message);
        }
    }, [ content ]);

    const onDownloadPlain = useCallback(() => {
        downloadFile(content, id + ".txt", "text/plain");
    }, [ id, content ]);

    const onDownloadDecoded = useCallback(() => {
        const decoded = atob(content);
        downloadFile(decoded, id + ".bin", "application/octet-stream");
    }, [ id, content ]);

    const menu = useMemo(() => {
        return {
            items: [
                {
                    key: "base64",
                    label: "Try base64 decoding",
                    onClick: onTryDecoding,
                },
                {
                    key: "download",
                    label: "Download as file",
                    onClick: onDownloadPlain,
                },
                {
                    key: "download-b64",
                    label: "Decode base64 and download as file",
                    onClick: onDownloadDecoded,
                },
            ],
        };
    }, [ onTryDecoding, onDownloadPlain, onDownloadDecoded ]);

    return (
        <Flex gap="1rem">
            <Flex vertical gap="1rem">
                <div>{content}</div>
                {decoded && <div>{decoded}</div>}
            </Flex>
            <Dropdown menu={menu}>
                <Button children="..." size="small" />
            </Dropdown>
        </Flex>
    );
}

function RowDetails({ row }) {
    const items = useMemo(() => {
        const items = [
            {
                key: "target_domain",
                label: "Target domain",
                children: row.target_domain,
            },
            {
                dataIndex: "protocol",
                key: "protocol",
                label: "Protocol",
                children: row.protocol,
            },
            {
                dataIndex: "port",
                key: "port",
                label: "Port",
                children: row.port,
            },
            {
                key: "headers",
                label: "Headers",
                children: row.headers,
            },
            {
                key: "content",
                label: "Payload",
                children: <Content id={row.id} content={row.content} />,
            },
            {
                key: "gzipped",
                label: "Payload gzipped",
                children: row.gzipped ? "Yes" : "No",
            },
            {
                key: "failed",
                label: "Failed to decode payload",
                children: row.failed_decoding ? "Yes" : "No",
            },
        ];

        if (row.forwarded_for) {
            items.push({
                key: "forwarded_for",
                label: "Forwarded for",
                children: <IpLink ip={row.forwarded_for} />,
            });
        }

        return items;
    }, [ row ]);

    return <Descriptions column={1} bordered size="small" items={items} />;
}

function TarpitLog({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowDateRange = true,
    allowSearch = true,
    allowCsv = true,
    allowMore = true,
    domainLink = true,
    entityLink = true,
    ipLink = true,
    excludeQueryOptions = [],
    onLoadMore = null,
    initialParams = {},
}) {
    const queryOptions = useQueryOptions(
        defaultQueryOptions,
        excludeQueryOptions,
    );
    const [ page, setPage ] = useState(0);
    const [ downloading, setDownloading ] = useState(false);
    const [ searchPayloadResults, setSearchPayloadResults ] = useState([]);
    const [ searchHeadersResults, setSearchHeadersResults ] = useState([]);

    const {
        query,
        setQuery,
        country,
        setCountry,
        queryBy,
        setQueryBy,
        payload,
        setPayload,
        blacklisted,
        setBlacklisted,
        all,
        apply,
    } = useDualState({ params, mode: stateMode, defaults: initialParams });

    const { data, isLoading, reset, extraData } = useQuery(
        loadTarpitLog,
        [ page, all, ...(Object.values(initialParams) || []) ],
        {
            rowIdKey: "id",
            params: {
                ...initialParams,
                page,
                pageSize: initialParams.pageSize || 25,
                ...all,
            },
            initialData: [],
            append: true,
            prepareParams: async (params) => {
                const { queryBy, query, page, payload } = params;
                const newParams = { ...params };

                delete newParams.payload;

                if (payload === "gzipped") {
                    newParams.gzipped = true;
                    newParams.hasPayload = true;
                }
                else if (payload === "ungzipped") {
                    newParams.gzipped = false;
                    newParams.hasPayload = true;
                }
                else if (payload === "no-payload") {
                    newParams.hasPayload = false;
                }

                if (queryBy === "payload" || queryBy === "headers") {
                    newParams.queryBy = null;
                    newParams.query = null;

                    if (page === 0) {
                        const search = query
                            .replace(/\s+/, " ")
                            .replace(/[^a-zA-Z0-9 \-@.*]/gi, "")
                            .replace(/\n/g, "")
                            .trim()
                            .toLowerCase();

                        const { data, error } = await supabasePayloads
                            .from(
                                queryBy === "payload"
                                    ? "tarpit_payload"
                                    : "tarpit_header",
                            )
                            .select("id")
                            .ilike("content", `%${search}%`)
                            .order("created_at", { ascending: false })
                            .limit(100);

                        const results = data.map((r) => r.id);

                        if (error) {
                            console.error(error);
                        }

                        if (queryBy === "payload") {
                            newParams.payloadId = results;
                            setSearchPayloadResults(results);
                        }
                        else {
                            newParams.headersId = results;
                            setSearchHeadersResults(results);
                        }
                    }
                    else {
                        if (queryBy === "payload") {
                            newParams.payloadId = searchPayloadResults;
                        }
                        else {
                            newParams.headersId = searchHeadersResults;
                        }
                    }
                }

                return newParams;
            },
        },
        Object.values(initialParams) || [],
    );

    const { hasMore } = extraData;

    useUpdateEffect(() => reset(), [ all, reset ]);

    const loadNextPage = useCallback(() => {
        onLoadMore ? onLoadMore() : setPage((prev) => prev + 1);
    }, [ onLoadMore ]);

    const onQueryChange = useCallback(
        (e) => {
            const query = e.target.value.trim();

            if (query) {
                if (ipaddr.isValid(query)) {
                    if (queryBy !== "forwardedFor") {
                        setQueryBy("ip");
                    }
                }
                else if (
                    query.indexOf("/") !== -1
                    && ipaddr.isValid(query.split("/")[0])
                ) {
                    setQueryBy("cidr");
                }
                else if (query.match(domainNameRegex)) {
                    setQueryBy("domain");
                }
                else {
                    if (
                        !queryBy
                        || queryBy === "companyDomain"
                        || queryBy === "cidr"
                        || queryBy === "ip"
                    ) {
                        setQueryBy("payload");
                    }
                }
            }
            else {
                setQueryBy(undefined);
            }

            setQuery(e.target.value);
        },
        [ queryBy, setQuery, setQueryBy ],
    );

    const onSearchClick = useCallback(
        async () => {
            reset();
            setPage(0);
            async(apply);
        },
        [ setPage, apply, reset ],
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [ onSearchClick ],
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadTarpitLog({
                ...initialParams,
                query,
                /*dateRange,*/ page,
                csv: true,
            });
            downloadCsv(csv, "tarpit_log.csv");
            setDownloading(false);
        },
        [
            page,
            query,
            ...(Object.values(initialParams) || []), /*, dateRange*/
        ],
    );

    const renderExpanded = useCallback((row) => <RowDetails row={row} />, []);

    const expandable = useMemo(
        () => ({
            expandedRowRender: renderExpanded,
            rowExpandable: () => true,
        }),
        [ renderExpanded ],
    );

    const tableColumns = useMemo(
        () => {
            const fetchedColumns = extraData.columns || [];
            let tableColumns = columns.filter(
                (c) => fetchedColumns.indexOf(c.dataIndex) !== -1,
            );

            if (domainLink === false) {
                const inx = tableColumns.findIndex((c) =>
                    c.dataIndex === "domain"
                );
                if (inx !== -1) {
                    delete tableColumns[inx].render;
                }
            }

            if (ipLink === false) {
                const inx = tableColumns.findIndex(
                    (c) => c.dataIndex === "source_ip",
                );
                if (inx !== -1) {
                    delete tableColumns[inx].render;
                }
            }
            else if (typeof ipLink === "function") {
                const inx = tableColumns.findIndex(
                    (c) => c.dataIndex === "source_ip",
                );
                if (inx !== -1) {
                    tableColumns[inx].render = (ip, row) =>
                        ipLink(ip, row.blacklisted);
                }
            }

            if (entityLink === false) {
                const inx = tableColumns.findIndex(
                    (c) => c.dataIndex === "company_name",
                );
                if (inx !== -1) {
                    delete tableColumns[inx].render;
                }
            }

            return tableColumns;
        },
        [ extraData.columns, domainLink, ipLink, entityLink ],
    );

    const onSelectOption = useCallback(
        ({ key }) => {
            const [ type, value ] = key.split("/");
            if (type === "blacklists") {
                setBlacklisted(
                    value === "" ? null : value === "true" ? true : false,
                );
            }
            else if (type === "payload") {
                setPayload(value || null);
            }
        },
        [ setBlacklisted, setPayload ],
    );

    const optionsMenu = useMemo(
        () => {
            const blacklistOptions = [
                {
                    key: "blacklists/",
                    label: "All IPs",
                    icon: blacklisted === null ? <CheckOutlined /> : null,
                },
                {
                    key: "blacklists/true",
                    label: "Only blacklisted",
                    icon: blacklisted === true ? <CheckOutlined /> : null,
                },
                {
                    key: "blacklists/false",
                    label: "Only not blacklisted",
                    icon: blacklisted === false ? <CheckOutlined /> : null,
                },
            ];

            const payloadOptions = [
                {
                    key: "payload/",
                    label: "All payloads",
                    icon: payload === null ? <CheckOutlined /> : null,
                },
                {
                    key: "payload/ungzipped",
                    label: "Show ungzipped",
                    icon: payload === "ungzipped" ? <CheckOutlined /> : null,
                },
                {
                    key: "payload/gzipped",
                    label: "Show gzipped",
                    icon: payload === "gzipped" ? <CheckOutlined /> : null,
                },
                {
                    key: "payload/no-payload",
                    label: "Without payload",
                    icon: payload === "no-payload" ? <CheckOutlined /> : null,
                },
            ];

            const options = [
                {
                    value: null,
                    label: "Payloads",
                    type: "group",
                    children: payloadOptions,
                },
                {
                    value: null,
                    label: "Blacklists",
                    type: "group",
                    children: blacklistOptions,
                },
            ];

            return {
                items: options,
                size: toolbarSize,
                multiple: true,
                onClick: onSelectOption,
                selectedKeys: [
                    `blacklists/${
                        blacklisted === true
                            ? "true"
                            : blacklisted === false
                            ? "false"
                            : ""
                    }`,
                    "payload/" + (payload || ""),
                ].filter(Boolean),
            };
        },
        [ toolbarSize, onSelectOption, blacklisted, payload ],
    );

    // console.log(tableColumns, extraData.columns)

    return (
        <div className="table-dns-log">
            {showToolbar && (
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowSearch && (
                            <>
                                <CountrySelector
                                    placeholder="Country"
                                    allowClear
                                    showSearch
                                    size={toolbarSize}
                                    value={country}
                                    onChange={setCountry} />
                                <Dropdown trigger="click" menu={optionsMenu}>
                                    <Button
                                        size={toolbarSize}
                                        children="Options"
                                        icon={<DownOutlined />}
                                        iconPosition="end" />
                                </Dropdown>
                                <Input
                                    addonBefore={
                                        <Select
                                            placeholder="Query by"
                                            value={queryBy}
                                            onChange={setQueryBy}
                                            options={queryOptions}
                                            size={toolbarSize} />
                                    }
                                    allowClear
                                    onKeyDown={onKeyDown}
                                    prefix={<SearchOutlined />}
                                    onChange={onQueryChange}
                                    value={query}
                                    size={toolbarSize} />
                            </>
                        )}
                        {(allowDateRange || allowSearch) && (
                            <Button
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search"
                                size={toolbarSize} />
                        )}
                        {allowCsv && (
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={downloading
                                        ? <SyncOutlined spin />
                                        : <DownloadOutlined />} />
                            </Tooltip>
                        )}
                    </div>
                </Portal>
            )}
            <Table
                sticky
                size="small"
                bordered
                loading={data.length === 0 && isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="id"
                locale={data.length === 0 && !isLoading ? locale : undefined}
                expandable={expandable}
                pagination={false} />
            {allowMore && hasMore && (
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }} />
                </>
            )}
        </div>
    );
}

export default TarpitLog;
