import {
    useMemo,
    useState,
    useCallback,
    useContext,
    useImperativeHandle,
    forwardRef,
    Fragment,
} from "react";
import {
    Typography,
    Table,
    Button,
    Tooltip,
    Input,
    App,
    Dropdown,
    Tag,
    Popconfirm,
    Spin,
    Flex,
} from "antd";
import {
    SearchOutlined,
    SyncOutlined,
    ExclamationCircleFilled,
    MinusCircleFilled,
    RightCircleOutlined,
    BellOutlined,
} from "@ant-design/icons";
import { useOn } from "@kuindji/observable-react";
import { Link } from "react-router-dom";

import CompanySeletorDialog from "components/selector/IpRegistryDialog";
import CompanyLink from "components/link/Company";
import IpLink from "components/link/Ip";
import WatchlistEditor from "components/watchlist/Editor";
import AddIpsForm from "components/watchlist/AddIps";
import AddDomainsForm from "components/watchlist/AddDomains";
import AddQueriesForm from "components/watchlist/AddQueries";
import { Portal } from "components/Portal";

import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main";
import useToggle from "hooks/useToggle";
import useDualState from "hooks/useDualState";
import useArrayToggle from "hooks/useArrayToggle";
import useUpdateEffect from "hooks/useUpdateEffect";
import useKey from "hooks/useKey";

import Context from "lib/Context";
import WatchlistContext from "components/watchlist/Context";
import renderGMT from "lib/renderGMT";
import checkIsIp from "lib/isIp";
import checkIsCidr from "lib/isCidr";
import formatNumber from "lib/format/number";
import aws from "lib/aws";
import exportMatches from "lib/watchlist/exportMatches";
import app from "appContext";
import EditDescriptionDialog from "./EditDescription";
import { loadIpBlacklist } from "api/ip";

// const WATCHLISTS_PER_PAGE = 50;
const ITEMS_PER_PAGE = 20;

function NotificationsDisabled() {
    return (
        <span className="notifications-disabled">
            <Tooltip title="Notifications disabled">
                <BellOutlined />
            </Tooltip>
        </span>
    );
}

async function loadWatchlists(params = {}) {
    const { query } = params;
    let isIp = false;
    let isCidr = false;
    if (query) {
        isIp = checkIsIp(query);
        isCidr = checkIsCidr(query);
    }

    const currentUserId = app.get("userId");
    const profile = app.get("userProfile");
    const companyIds = profile?.company?.map((c) => c.id).join(",") || null;

    const gql = `
        id, name, ip_count, company_id,
        matched_ip_count, is_company_default,
        company(id, name)
    `;
    const req = supabase.from("watchlist").select(gql, { count: "exact" });

    //.order("created_at", { ascending: false });

    if (
        (!params.user_id && !params.company_id) ||
        (params.user_id &&
            params.user_id === currentUserId &&
            !params.company_id)
    ) {
        if (companyIds !== null) {
            req.or(
                `company_id.in.(${companyIds}), user_id.eq.${currentUserId}`
            );
            req.order("name", {
                referencedTable: "company",
                ascending: true,
                nullsFirst: true,
            });
        } else {
            req.eq("user_id", currentUserId);
        }
    } else if (params.user_id) {
        req.eq("user_id", params.user_id);
    } else if (params.company_id) {
        req.eq("company_id", params.company_id);
    }

    req.order("name", { ascending: true });

    if (query) {
        if (isIp) {
            const ipreq = supabase
                .from("watchlist_ip")
                .select("watchlist_id")
                .eq("ip", query)
                .order("ip");
            if (params.user_id) {
                ipreq.eq("watchlist.user_id", params.user_id);
            }
            if (params.company_id) {
                ipreq.eq("watchlist.company_id", params.company_id);
            }
            const { data: ips } = await ipreq;

            if (ips) {
                req.in(
                    "id",
                    ips
                        .map((i) => i.watchlist_id)
                        .filter((id, inx, self) => self.indexOf(id) === inx)
                );
            }

            req.eq("watchlist_ip.ip", query);
        } else if (isCidr) {
            const ipreq = supabase
                .from("watchlist_cidr")
                .select("watchlist_id")
                .eq("cidr", query)
                .order("cidr");
            if (params.user_id) {
                ipreq.eq("watchlist.user_id", params.user_id);
            }
            if (params.company_id) {
                ipreq.eq("watchlist.company_id", params.company_id);
            }
            const { data: cidrs } = await ipreq;

            if (cidrs) {
                req.in(
                    "id",
                    cidrs
                        .map((i) => i.watchlist_id)
                        .filter((id, inx, self) => self.indexOf(id) === inx)
                );
            }

            req.eq("watchlist_cidr.cidr", query);
        } else {
            req.ilike("name", `%${query}%`);
        }
    }

    const resp = await req;
    const uws = resp.data.filter((row) => !row.company_id);
    for (const uw of uws.reverse()) {
        const inx = resp.data.indexOf(uw);
        resp.data.splice(inx, 1);
        resp.data.unshift(uw);
    }

    const wids = resp.data.map((row) => row.id);
    const ntfReq = supabase
        .from("watchlist_notification")
        .select("watchlist_id");
    ntfReq.eq("user_id", currentUserId);
    ntfReq.in("watchlist_id", wids);
    ntfReq.eq("active", true);
    const ntfResp = await ntfReq;

    ntfResp.data.forEach((ntf) => {
        const wid = ntf.watchlist_id;
        const w = resp.data.find((row) => row.id === wid);
        if (w) {
            w.has_new_matches = true;
        }
    });

    return resp;
}

async function updateWatchlistCompanies({ watchlist, entityIds }) {
    while (entityIds.length > 0) {
        const part = entityIds.splice(0, 100);
        await aws.backend.post("/watchlist/add_entity", {
            body: {
                watchlistId: watchlist.id,
                entityId: part,
            },
        });
    }
}

async function assignBlacklistedIps({ rows, ipKey, blacklistedKey }) {
    const ips = (rows?.map((row) => row[ipKey]) || []).filter((ip) => ip);
    if (ips.length > 0) {
        const { data: blacklisted } = await loadIpBlacklist({ ip: ips });
        blacklisted.forEach((brow) => {
            const inx = rows.findIndex((row) => row[ipKey] === brow.ip);
            if (inx !== -1) {
                rows[inx][blacklistedKey] = true;
                if (
                    blacklistedKey + "_description" in rows[inx] &&
                    !rows[inx][blacklistedKey + "_description"]
                ) {
                    rows[inx][blacklistedKey + "_description"] =
                        brow.description;
                }
            }
        });
    }
}

async function loadWatclistContents({
    watchlistId,
    table,
    page = 0,
    pageSize = ITEMS_PER_PAGE,
    dataIndex,
}) {
    let graph = "*";

    if (dataIndex === "entity_id") {
        graph += ", entity(name, domain)";
    }
    if (dataIndex === "ip" || dataIndex === "domain") {
        graph += `, ip_source:ip(last_dns_log_match, last_tarpit_log_match, 
                        dns_log_counter, tarpit_log_counter, 
                        country)`;
    }

    graph += `, watchlist_notification(active, disabled)`;

    const req = supabase
        .from(table)
        .select(graph, { count: "estimated" })
        .eq("watchlist_id", watchlistId);

    if (dataIndex === "ip" || dataIndex === "domain" || dataIndex === "cidr") {
        req.order("last_match_at", { ascending: false, nullsFirst: false });
    } else {
        req.order("matched", { ascending: false });
    }

    if (dataIndex) {
        req.order(dataIndex, { ascending: true });
    }

    req.range(page * pageSize, page * pageSize + pageSize - 1);

    const { data, error, count } = await req;

    if (dataIndex === "ip" || dataIndex === "domain" || dataIndex === "query") {
        const blacklistedKey =
            dataIndex === "ip"
                ? "blacklisted"
                : dataIndex === "domain"
                ? "ip_blacklisted"
                : "ip_reference_blacklisted";
        const ipKey = dataIndex === "query" ? "ip_reference" : "ip";
        await assignBlacklistedIps({ rows: data, ipKey, blacklistedKey });
    }

    return { data, error, count };
}

async function loadIpMatches({
    watchlistId,
    cidr,
    entityId,
    page = 0,
    pageSize = ITEMS_PER_PAGE,
}) {
    const graph = `ip, last_dns_log_match, last_tarpit_log_match, 
                    ip_source:ip(dns_log_counter, tarpit_log_counter, country)`;

    const req = supabase
        .from("watchlist_ip_match")
        .select(graph, { count: "estimated" })
        .eq("watchlist_id", watchlistId)
        .eq(cidr ? "cidr" : "entity_id", cidr || entityId)
        .order("last_match", { ascending: false })
        .range(page * pageSize, page * pageSize + pageSize - 1);

    const { data, error } = await req;

    await assignBlacklistedIps({
        rows: data,
        ipKey: "ip",
        blacklistedKey: "blacklisted",
    });

    return { data, error };
}

function IpMatches({ watchlistId, cidr, entityId, ipLink = null }) {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);

    const { data, count, isLoading } = useQuery(
        loadIpMatches,
        [page, watchlistId, cidr, entityId],
        {
            enabled: !!watchlistId,
            params: {
                page,
                pageSize,
                watchlistId,
                cidr,
                entityId,
            },
        }
    );

    const renderIpLink = useCallback(
        (ip, blacklisted, description) => {
            if (ipLink) {
                return ipLink(ip, blacklisted, description);
            }
            return (
                <IpLink
                    ip={ip}
                    blacklisted={blacklisted}
                    blacklistDescription={description}
                />
            );
        },
        [ipLink]
    );

    const columns = useMemo(() => {
        return [
            {
                key: "ip",
                dataIndex: "ip",
                title: "IP",
                render: (ip, row) =>
                    renderIpLink(
                        ip,
                        row.blacklisted,
                        row.blacklisted_description
                    ),
                width: 200,
            },
            {
                key: "ip_source",
                dataIndex: "ip_source",
                title: "IP info",
                render: (_, row) => {
                    const ip_source = row.ip_source;
                    let info = [];
                    if (ip_source.country) {
                        info.push(ip_source.country);
                    }
                    const date =
                        row.last_dns_log_match && row.last_tarpit_log_match
                            ? row.last_dns_log_match < row.last_tarpit_log_match
                                ? row.last_tarpit_log_match
                                : row.last_dns_log_match
                            : row.last_dns_log_match ||
                              row.last_tarpit_log_match;
                    info.push("Last match: " + renderGMT(date));
                    info.push(
                        "Total requests: " +
                            formatNumber(
                                ip_source.dns_log_counter +
                                    ip_source.tarpit_log_counter,
                                2
                            )
                    );

                    return info.join(", ");
                },
            },
        ];
    }, [renderIpLink]);

    const onPaginationChange = useCallback((page, pageSize) => {
        setPage(page - 1);
        setPageSize(pageSize);
    }, []);

    const pagination = useMemo(() => {
        return {
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            total: count,
            defaultPageSize: 50,
            onChange: onPaginationChange,
            pageSize,
        };
    }, [onPaginationChange, count, pageSize]);

    return (
        <Table
            size="small"
            bordered
            loading={isLoading}
            rowSelection={false}
            expandable={false}
            tableLayout="auto"
            rowKey="id"
            dataSource={data}
            columns={columns}
            pagination={pagination}
        />
    );
}

function WatchlistContents(
    {
        watchlist,
        table = "watchlist_ip",
        ipLink = null,
        dataIndex = "ip",
        name = "IP",
        selectable = false,
    },
    ref
) {
    const context = useContext(WatchlistContext);
    const { modal } = App.useApp();
    const [selection, setSelection] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [editDescription, setEditDescription] = useState(null);

    const { data, count, isLoading, isLoaded, refetch } = useQuery(
        loadWatclistContents,
        [page, dataIndex, watchlist?.id],
        {
            enabled: !!watchlist?.id,
            params: {
                table,
                dataIndex,
                page,
                pageSize,
                watchlistId: watchlist?.id,
            },
        }
    );
    
    useImperativeHandle(ref, () => ({ data, isLoading, isLoaded }), [
        data,
        isLoading,
        isLoaded,
    ]);

    const onRefetchContents = useCallback(
        (id) => {
            if (id === watchlist.id) {
                refetch();
            }
        },
        [refetch, watchlist]
    );

    const renderIpLink = useCallback(
        (ip, blacklisted = false, description = null) => {
            if (ipLink) {
                return ipLink(ip, blacklisted, description);
            }
            return (
                <IpLink
                    ip={ip}
                    blacklisted={blacklisted}
                    blacklistDescription={description}
                />
            );
        },
        [ipLink]
    );

    useOn(context, `refetch-${dataIndex}`, onRefetchContents);

    const updateNotification = useCallback(
        async (id, updates) => {
            await supabase
                .from("watchlist_notification")
                .update(updates)
                .eq("user_id", app.get("userId"))
                .eq("watchlist_id", watchlist.id)
                .eq(table, id);

            await new Promise((resolve) => setTimeout(resolve, 500));

            refetch();
            context.trigger("refetch");
        },
        [refetch, table, watchlist, context]
    );

    const onSetIpRead = useCallback(
        async (id) => {
            await updateNotification(id, { active: false });
        },
        [updateNotification]
    );

    const onSetNotificationDisabled = useCallback(
        async (id) => {
            await updateNotification(id, { active: false, disabled: true });
        },
        [updateNotification]
    );

    const onSetNotificationEnabled = useCallback(
        async (id) => {
            await updateNotification(id, { disabled: false });
        },
        [updateNotification]
    );

    const onDeleteIpClick = useCallback(
        (id) => {
            modal.confirm({
                content: "Are you sure?",
                onOk: async () => {
                    await supabase.from(table).delete().eq("id", id);
                    refetch();
                },
            });
        },
        [refetch, modal, table]
    );

    const columns = useMemo(() => {
        return [
            {
                key: dataIndex,
                dataIndex: dataIndex,
                title: name,
                width:
                    dataIndex === "ip" || dataIndex === "cidr"
                        ? 200
                        : undefined,
                render: (data, row) => {
                    const matched = row.matched;
                    const active = row.watchlist_notification?.[0]?.active;
                    const disabled = row.watchlist_notification?.[0]?.disabled;
                    const icon = matched ? (
                        <Tooltip title={active ? "New match" : "Old match"}>
                            <ExclamationCircleFilled
                                className={active ? "active" : ""}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="No matches">
                            <MinusCircleFilled />
                        </Tooltip>
                    );

                    const disabledIcon = disabled ? (
                        <NotificationsDisabled />
                    ) : null;

                    if (dataIndex === "ip") {
                        return (
                            <Flex gap="0.5rem">
                                {icon}
                                {renderIpLink(
                                    data,
                                    row.blacklisted,
                                    row.blacklisted_description
                                )}
                                {disabledIcon}
                            </Flex>
                        );
                    } else if (dataIndex === "cidr") {
                        return (
                            <Flex gap="0.5rem">
                                {icon}
                                <span>{data}</span>
                                {disabledIcon}
                            </Flex>
                        );
                    } else if (dataIndex === "entity_id") {
                        return (
                            <Flex gap="0.5rem">
                                {icon}
                                <CompanyLink
                                    id={row.entity_id}
                                    name={row.entity.name}
                                />
                                {row.entity.domain && (
                                    <span>- {row.entity.domain}</span>
                                )}
                                {disabledIcon}
                            </Flex>
                        );
                    } else if (dataIndex === "domain") {
                        return (
                            <Flex gap="0.5rem">
                                {icon}
                                <Link
                                    to={`/reports/domain/${row.domain}`}
                                    children={row.domain}
                                />
                                {row.ip && <span>-</span>}
                                {row.ip &&
                                    renderIpLink(
                                        row.ip,
                                        row.ip_blacklisted,
                                        row.ip_blacklisted_description
                                    )}
                                {disabledIcon}
                            </Flex>
                        );
                    } else if (dataIndex === "query") {
                        return (
                            <Flex gap="0.5rem">
                                {icon}
                                <span>{row.query}</span>
                                {disabledIcon}
                            </Flex>
                        );
                    }
                },
            },
            dataIndex === "query" && !watchlist.company_id
                ? {
                      key: "type",
                      dataIndex: "query",
                      title: "Match type",
                      render: (query, row) => {
                          if (row.header_id) {
                              return (
                                  <Link
                                      to={`/logs/tarpit?header_id=${row.header_id}`}
                                      children={
                                          <>
                                              Header&nbsp;
                                              <RightCircleOutlined />
                                          </>
                                      }
                                  />
                              );
                          } else if (row.payload_id) {
                              return (
                                  <Link
                                      to={`/logs/tarpit?payload_id=${row.payload_id}`}
                                      children={
                                          <>
                                              Payload&nbsp;
                                              <RightCircleOutlined />
                                          </>
                                      }
                                  />
                              );
                          }
                      },
                  }
                : null,
            dataIndex === "query"
                ? {
                      key: "ip_reference",
                      dataIndex: "ip_reference",
                      title: "IP",
                      render: (ip, row) => {
                          if (ip) {
                              return renderIpLink(
                                  ip,
                                  row.ip_reference_blacklisted,
                                  row.ip_reference_blacklisted_description
                              );
                          }
                      },
                  }
                : null,
            dataIndex === "query"
                ? {
                      key: "content",
                      dataIndex: "content",
                      title: "Content",
                      className: "watchlist-cell-wrap",
                  }
                : null,

            dataIndex === "ip" || dataIndex === "domain"
                ? {
                      key: "ip",
                      dataIndex: "ip_source",
                      title: "IP info",
                      render: (ip) => {
                          if (!ip) {
                              return null;
                          }

                          let info = [];
                          if (ip.country) {
                              info.push(ip.country);
                          }
                          const date =
                              ip.last_dns_log_match && ip.last_tarpit_log_match
                                  ? ip.last_dns_log_match <
                                    ip.last_tarpit_log_match
                                      ? ip.last_tarpit_log_match
                                      : ip.last_dns_log_match
                                  : ip.last_dns_log_match ||
                                    ip.last_tarpit_log_match;
                          info.push("Last match: " + renderGMT(date));
                          info.push(
                              "Total requests: " +
                                  formatNumber(
                                      ip.dns_log_counter + ip.tarpit_log_counter
                                  )
                          );

                          return info.join(", ");
                      },
                  }
                : null,
            dataIndex === "ip" || dataIndex === "cidr" || dataIndex === "query"
                ? {
                      key: "description",
                      dataIndex: "description",
                      title: "Description",
                  }
                : null,
            {
                className: "table-cell-collapse",
                key: "action",
                dataIndex: "id",
                render: (id, row) => {
                    const cmp_def = watchlist.is_company_default;
                    const items = [
                        {
                            key: "read",
                            label: "Mark as read",
                            onClick: () => onSetIpRead(row.id),
                        },
                        dataIndex === "ip" ||
                        dataIndex === "cidr" ||
                        dataIndex === "query"
                            ? {
                                  key: "description",
                                  label: "Edit description",
                                  onClick: () => setEditDescription(row),
                              }
                            : null,
                        {
                            key: "disable",
                            label: row.watchlist_notification?.[0]?.disabled
                                ? "Enable notifications"
                                : "Disable notifications",
                            onClick: () =>
                                row.watchlist_notification?.[0]?.disabled
                                    ? onSetNotificationEnabled(row.id)
                                    : onSetNotificationDisabled(row.id),
                        },
                        cmp_def === true
                            ? null
                            : {
                                  key: "delete",
                                  label: "Delete",
                                  danger: true,
                                  onClick: () => onDeleteIpClick(row.id),
                              },
                    ].filter((c) => !!c);
                    return (
                        <Dropdown menu={{ items }} trigger={["click"]}>
                            <Button size="small">...</Button>
                        </Dropdown>
                    );
                },
            },
        ].filter((c) => !!c);
    }, [
        onSetIpRead,
        onDeleteIpClick,
        dataIndex,
        name,
        renderIpLink,
        onSetNotificationDisabled,
        onSetNotificationEnabled,
        watchlist,
    ]);

    const onSelectionChange = useCallback(
        (selection) => {
            setSelection(selection);
            const watchlistSelection = [
                ...(context.get("watchlistSelection") || []),
            ];
            const selectionInWatchlists = {
                ...(context.get("selectionInWatchlists") || {}),
            };
            selectionInWatchlists[watchlist.id] = selection;

            const inx = watchlistSelection.indexOf(watchlist.id);
            if (selection.length > 0 && inx === -1) {
                watchlistSelection.push(watchlist.id);
            } else if (selection.length === 0 && inx !== -1) {
                watchlistSelection.splice(inx, 1);
            }

            context.set("watchlistSelection", watchlistSelection);
            context.set("selectionInWatchlists", selectionInWatchlists);
        },
        [context, watchlist]
    );

    const rowSelection = useMemo(() => {
        if (!selectable) {
            return false;
        }
        return {
            selectedRowKeys: selection,
            onChange: onSelectionChange,
        };
    }, [selection, onSelectionChange, selectable]);

    const renderIpMatches = useCallback(
        (row) => {
            return (
                <IpMatches
                    watchlistId={row.watchlist_id}
                    cidr={row.cidr}
                    entityId={row.entity_id}
                    ipLink={ipLink}
                />
            );
        },
        [ipLink]
    );

    const expandable = useMemo(() => {
        return {
            rowExpandable: (row) => {
                if (
                    table !== "watchlist_cidr" &&
                    table !== "watchlist_company"
                ) {
                    return false;
                }
                return row.matched;
            },
            expandedRowRender: renderIpMatches,
            defaultExpandAllRows: false,
        };
    }, [table, renderIpMatches]);

    const onPaginationChange = useCallback((page, pageSize) => {
        setPage(page - 1);
        setPageSize(pageSize);
    }, []);

    const onCancelEditDescription = useCallback(
        () => setEditDescription(null),
        []
    );

    const onEditDescriptionSuccess = useCallback(() => {
        setEditDescription(null);
        refetch();
    }, [refetch]);

    const pagination = useMemo(() => {
        return {
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            total: count,
            defaultPageSize: 50,
            onChange: onPaginationChange,
            pageSize,
        };
    }, [onPaginationChange, count, pageSize]);

    if (data.length === 0) {
        return null;
    }

    return (
        <>
            <Table
                size="small"
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                expandable={expandable}
                tableLayout="auto"
                rowKey="id"
                dataSource={data}
                columns={columns}
                pagination={pagination}
            />
            <EditDescriptionDialog
                open={!!editDescription}
                item={editDescription}
                onCancel={onCancelEditDescription}
                onSuccess={onEditDescriptionSuccess}
            />
        </>
    );
}

const WatchlistContentsWithRef = forwardRef(WatchlistContents);

const params = [
    {
        name: "query",
    },
];

function WatchlistExpanded({ watchlist, ipLink = null, selectable = false }) {
    const [company, setCompany] = useState(null);
    const [ip, setIp] = useState(null);
    const [cidr, setCidr] = useState(null);
    const [domain, setDomain] = useState(null);
    const [query, setQuery] = useState(null);

    const isLoading = useMemo(() => {
        for (const section of [company, ip, cidr, domain, query]) {
            if (section?.isLoaded === true) {
                return false;
            }
        }
        return true;
    }, [company, ip, cidr, domain, query]);

    return (
        <Flex vertical gap="0.5rem">
            <Typography.Title level={4}>{watchlist.name}</Typography.Title>
            {isLoading && <Spin spinning />}
            <WatchlistContentsWithRef
                ipLink={ipLink}
                ref={setCompany}
                watchlist={watchlist}
                table="watchlist_company"
                name="Entity"
                dataIndex="entity_id"
                selectable={selectable}
            />
            <WatchlistContentsWithRef
                ipLink={ipLink}
                ref={setIp}
                watchlist={watchlist}
                table="watchlist_ip"
                name="IP"
                dataIndex="ip"
                selectable={selectable}
            />
            <WatchlistContentsWithRef
                ipLink={ipLink}
                ref={setCidr}
                watchlist={watchlist}
                table="watchlist_cidr"
                name="CIDR"
                dataIndex="cidr"
                selectable={selectable}
            />
            <WatchlistContentsWithRef
                ipLink={ipLink}
                ref={setDomain}
                watchlist={watchlist}
                table="watchlist_domain"
                name="Domain"
                dataIndex="domain"
                selectable={selectable}
            />
            <WatchlistContentsWithRef
                ipLink={ipLink}
                ref={setQuery}
                watchlist={watchlist}
                table="watchlist_tarpit_query"
                name="Query"
                dataIndex="query"
                selectable={selectable}
            />
        </Flex>
    );
}

function Watchlists(
    {
        title = "Watchlists",
        toolbarPortal = null,
        before = null,
        toolbarSize = "default",
        stateMode = "state",
        showToolbar = true,
        userId = null,
        companyId = null,
        selectable = false,
        ipLink = null,
        onLoad,
    },
    ref
) {
    const context = useMemo(
        () =>
            new Context({
                selection: [],
                selectionInWatchlists: {},
                watchlistSelection: [],
            }),
        []
    );

    const { query, setQuery, all, apply } = useDualState({
        params,
        mode: stateMode,
    });
    // const [page, setPage] = useState(0);
    // const [pageSize, setPageSize] = useState(WATCHLISTS_PER_PAGE);
    const [editWl, setEditWl] = useState(null);
    const [showForm, , , openForm, hideForm] = useToggle(false);
    const { modal } = App.useApp();
    const [read, toggleRead] = useArrayToggle();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [exporting, toggleExporting] = useArrayToggle();

    const watchlistSelection = useKey("watchlistSelection", context);
    const selection = useKey("selection", context);
    const addCompanyToWatchlist = useKey("addCompanyToWatchlist", context);

    //page, pageSize,
    //count,
    const { data, isLoading, reset, refetch } = useQuery(
        loadWatchlists,
        [userId, companyId, all],
        {
            enabled: !!userId || !!companyId,
            params: {
                ...all,
                // page,
                // pageSize,
                user_id: userId,
                company_id: companyId,
            },
            initialData: [],
            onLoad,
        }
    );

    useImperativeHandle(
        ref,
        () => ({
            refetch,
        }),
        [refetch]
    );

    useOn(context, "refetch", refetch);

    useUpdateEffect(() => reset(), [all]);

    const onEditWatchlistClick = useCallback(
        (wl) => {
            setEditWl(wl);
            openForm();
        },
        [openForm]
    );

    const onSetWatchlistRead = useCallback(
        async (id) => {
            toggleRead(id, true);

            await supabase
                .from("watchlist_notification")
                .update({ active: false })
                .eq("user_id", app.get("userId"))
                .eq("watchlist_id", id);

            refetch();
            toggleRead(id, false);
        },
        [refetch, toggleRead]
    );

    const onAddEntityClose = useCallback(() => {
        context.set("addCompanyToWatchlist", null);
    }, [context]);

    const onAddEntity = useCallback(
        async (ids) => {
            setIsAdding(true);
            const watchlist = context.get("addCompanyToWatchlist");
            await updateWatchlistCompanies({
                watchlist,
                entityIds: ids,
            });
            context.trigger("refetch-company_id", watchlist.id);
            setIsAdding(false);
        },
        [context]
    );

    const onExportMatches = useCallback(
        async (w) => {
            toggleExporting(w.id, true);
            await exportMatches(w.id);
            toggleExporting(w.id, false);
        },
        [toggleExporting]
    );

    const onDeleteWatchlistClick = useCallback(
        (id) => {
            modal.confirm({
                content: "Are you sure?",
                onOk: async () => {
                    await supabase.from("watchlist").delete().eq("id", id);
                    refetch();
                },
            });
        },
        [refetch, modal]
    );

    const onDeleteContentsClick = useCallback(async () => {
        setIsDeleting(true);
        const watchlists = context.get("selection") || [];
        const contents = context.get("selectionInWatchlists") || {};
        if (contents) {
            const groups = Object.keys(contents);
            for (let i = 0, l = groups.length; i < l; i++) {
                const ids = contents[groups[i]];
                if (ids.length > 0) {
                    await supabase.from("watchlist_ip").delete().in("id", ids);
                    await supabase
                        .from("watchlist_cidr")
                        .delete()
                        .in("id", ids);
                    await supabase
                        .from("watchlist_company")
                        .delete()
                        .in("id", ids);
                    await supabase
                        .from("watchlist_domain")
                        .delete()
                        .in("id", ids);
                }
            }
        }

        if (watchlists.length > 0) {
            await supabase.from("watchlist").delete().in("id", watchlists);
        }

        context.set("selection", []);
        context.set("watchlistSelection", []);
        context.set("selectionInWatchlists", {});

        refetch();
        setIsDeleting(false);
    }, [context, refetch]);

    const dataByCompany = useMemo(() => {
        const companies = {};
        for (const row of data) {
            const companyId = row.company_id || "";
            if (!companies[companyId]) {
                companies[companyId] = {
                    company: row.company,
                    watchlists: [],
                };
            }
            companies[companyId].watchlists.push(row);
        }
        return Object.values(companies);
    }, [data]);

    const columns = useMemo(() => {
        return [
            {
                key: "name",
                dataIndex: "name",
                title: "Name",
            },
            {
                key: "matches",
                dataIndex: "watchlist_ip",
                title: "Has matches",
                width: 120,
                render: (_, row) => {
                    const cnt = row.matched_ip_count;
                    if (cnt > 0) {
                        return (
                            <Tag color="warning" children={formatNumber(cnt)} />
                        );
                    }
                    return "No";
                },
            },
            {
                key: "has_new_matches",
                dataIndex: "has_new_matches",
                title: "New matches",
                width: 120,
                render: (_, row) => {
                    if (row.has_new_matches) {
                        return (
                            <Tag
                                color="error"
                                onClick={() => onSetWatchlistRead(row.id)}
                                icon={
                                    read.indexOf(row.id) !== -1 ? (
                                        <SyncOutlined spin />
                                    ) : null
                                }>
                                Yes
                            </Tag>
                        );
                    }

                    return "No";
                },
            },
            {
                className: "table-cell-collapse",
                key: "action",
                dataIndex: "id",
                render: (id, row) => {
                    const cmp_def = row.is_company_default;
                    const items = [
                        cmp_def
                            ? null
                            : {
                                  key: "add-ip/cidr",
                                  label: "Add IPs/CIDRs",
                                  onClick: () =>
                                      context.set("addIpsToWatchlist", row),
                              },
                        cmp_def
                            ? null
                            : {
                                  key: "add-company",
                                  label: "Add entities",
                                  onClick: () =>
                                      context.set("addCompanyToWatchlist", row),
                              },
                        cmp_def
                            ? null
                            : {
                                  key: "add-domain",
                                  label: "Add domains",
                                  onClick: () =>
                                      context.set("addDomainsToWatchlist", row),
                              },
                        cmp_def
                            ? null
                            : {
                                  key: "add-query",
                                  label: "Add queries",
                                  onClick: () =>
                                      context.set("addQueriesToWatchlist", row),
                              },
                        {
                            key: "read",
                            label: "Mark as read",
                            onClick: () => onSetWatchlistRead(row.id),
                        },
                        cmp_def
                            ? null
                            : {
                                  key: "edit",
                                  label: "Edit",
                                  onClick: () => onEditWatchlistClick(row),
                              },
                        row.matched_ip_count > 0
                            ? {
                                  key: "export",
                                  label: "Export matches",
                                  onClick: () => onExportMatches(row),
                              }
                            : null,
                        cmp_def
                            ? null
                            : {
                                  key: "delete",
                                  label: "Delete",
                                  danger: true,
                                  onClick: () => onDeleteWatchlistClick(row.id),
                              },
                    ].filter((i) => !!i);
                    return (
                        <Dropdown menu={{ items }} trigger={["click"]}>
                            <Button
                                size="small"
                                loading={exporting.indexOf(row.id) !== -1}
                                children={
                                    exporting.indexOf(row.id) !== -1
                                        ? null
                                        : "..."
                                }
                            />
                        </Dropdown>
                    );
                },
            },
        ];
    }, [
        onEditWatchlistClick,
        onDeleteWatchlistClick,
        onExportMatches,
        onSetWatchlistRead,
        read,
        context,
        exporting,
    ]);

    /*const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            if (count <= WATCHLISTS_PER_PAGE) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: WATCHLISTS_PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [count, onPaginationChange, pageSize]
    );*/

    const renderIps = useCallback(
        (wl) => {
            return (
                <WatchlistExpanded
                    watchlist={wl}
                    ipLink={ipLink}
                    selectable={selectable}
                />
            );
        },
        [ipLink, selectable]
    );

    const expandable = useMemo(() => {
        return {
            rowExpandable: () => true,
            expandedRowRender: renderIps,
            defaultExpandAllRows: false,
        };
    }, [renderIps]);

    const onFormClose = useCallback(() => {
        hideForm();
        setEditWl(null);
    }, [hideForm]);

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                apply();
            }
        },
        [apply]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onSearchClick = useCallback(() => apply(), [apply]);

    const onSelectionChange = useCallback(
        (selection) => {
            context.set("selection", selection);
        },
        [context]
    );

    const rowSelection = useMemo(() => {
        if (!selectable) {
            return false;
        }
        return {
            selectedRowKeys: selection,
            onChange: onSelectionChange,
        };
    }, [selection, onSelectionChange, selectable]);

    const fetchSelectedEntities = useCallback(
        async () =>
            loadWatclistContents({
                watchlistId: addCompanyToWatchlist.id,
                table: "watchlist_company",
            }),
        [addCompanyToWatchlist]
    );

    const isEntityDisabled = useCallback((entity, selectedEntities) => {
        console.log(entity, selectedEntities);
        return (
            selectedEntities?.find((e) => e.company_id === entity?.id) || false
        );
    }, []);

    return (
        <WatchlistContext.Provider value={context}>
            {showToolbar && (
                <Portal host={toolbarPortal}>
                    {before}
                    {title && (
                        <Typography.Title level={1}>{title}</Typography.Title>
                    )}
                    <div className="toolbar">
                        <Button
                            size={toolbarSize}
                            type="primary"
                            children="Create watchlist"
                            onClick={openForm}
                        />
                        {(watchlistSelection.length > 0 ||
                            selection.length > 0) && (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={onDeleteContentsClick}>
                                <Button
                                    size={toolbarSize}
                                    loading={isDeleting}
                                    type="primary"
                                    danger
                                    children="Delete selected"
                                />
                            </Popconfirm>
                        )}
                        <Input
                            size={toolbarSize}
                            allowClear
                            onKeyDown={onKeyDown}
                            prefix={<SearchOutlined />}
                            onChange={onQueryChange}
                            value={query}
                        />
                        <Button
                            size={toolbarSize}
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={onSearchClick}
                            children="Search"
                        />
                    </div>
                </Portal>
            )}
            {dataByCompany.map((row, inx) => (
                <Fragment key={inx}>
                    {!companyId && row.company && <br />}
                    {!companyId && row.company && (
                        <Typography.Title level={3}>
                            {row.company.name}
                        </Typography.Title>
                    )}
                    <Table
                        className="watchlists-table"
                        sticky
                        size="small"
                        bordered
                        tableLayout="auto"
                        loading={isLoading || isDeleting || isAdding}
                        dataSource={row.watchlists}
                        columns={columns}
                        rowKey="id"
                        rowSelection={rowSelection}
                        expandable={expandable}
                        pagination={false}
                    />
                </Fragment>
            ))}

            <WatchlistEditor
                watchlist={editWl}
                open={showForm}
                onSave={refetch}
                onClose={onFormClose}
                userId={userId}
                companyId={companyId}
            />
            <AddIpsForm />
            <AddDomainsForm />
            <AddQueriesForm />
            <CompanySeletorDialog
                open={!!addCompanyToWatchlist}
                onClose={onAddEntityClose}
                onSelect={onAddEntity}
                fetchSelected={fetchSelectedEntities}
                isDisabled={isEntityDisabled}
            />
        </WatchlistContext.Provider>
    );
}

export default forwardRef(Watchlists);
